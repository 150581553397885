import React, { Component } from "react";
import "./ApplyPage.css";
import faqIcon from "../images/faq_icon.png";
import contactIcon from "../images/contact_icon.png";
import contactPic from "../images/contact_pic.png";
import mailboxIcon from "../images/mailbox_icon.png";
import conversationIcon from "../images/conversation_icon.png";

class ApplyPage extends Component {
  redirectToForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSeg4POvdMrEjCW36de1-Wk4jS4shSKPC5_Dh_zw0bnspBdYrg/viewform",
      "_blank"
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* Main Section */}
        <div className='apply-page-main-section flex w-full bg-cover bg-center flex-col'>
          <h1 className='font-bold text-white mt-56 self-center'>加入CPU</h1>
          <button
            className='mt-28 self-center font-bold text-white py-4 px-6'
            onClick={this.redirectToForm}
          >
            Apply Now
          </button>
        </div>
        {/* FAQ Section */}
        <div className='apply-page-faq-section py-20'>
          <div className='flex flex-col sm:flex-row sm:justify-center'>
            <h1 className='apply-page-h1 font-bold self-center sm:self-end'>
              FAQ
            </h1>
            <img className='self-center sm:ml-24' src={faqIcon} alt=''></img>
          </div>
          <div className='my-16 px-8'>
            <h2 className='apply-page-h2 font-bold text-center'>
              CPU每周有固定的commitment吗？
            </h2>
            <p className='apply-page-p text-center mt-4'>
              并没有哦！CPU的目的在于服务学生，让学生在学校这样一个偌大的环境里找到属于自己的一个圈子。
            </p>
          </div>
          <div className='my-16 px-8'>
            <h2 className='apply-page-h2 font-bold text-center'>
              CPU都有什么活动呀？
            </h2>
            <p className='apply-page-p text-center mt-4'>
              KCON，夜市，摄影大赛，...
            </p>
          </div>
          <div className='my-16 px-8'>
            <h2 className='apply-page-h2 font-bold text-center'>
              我是Chinese American， 但我很想交一些国际学生朋友，
              请问我可以加入吗？
            </h2>
            <p className='apply-page-p text-center mt-4'>当然可以！</p>
          </div>
        </div>
        {/* More Question Section */}
        <div className='apply-page-more-q-section py-20'>
          <h1 className='apply-page-h1 font-bold text-center'>更多问题？</h1>
          <div className='flex flex-col md:flex-row md:justify-center my-20 px-8'>
            <div className='flex flex-col md:w-72'>
              <img className='self-center icon' src={contactIcon} alt=''></img>
              <h2 className='apply-page-h2 my-8 text-center'>
                通过微信联系我们的客服
              </h2>
              <img className='self-center' src={contactPic} alt=''></img>
            </div>

            <div className='flex flex-col md:w-72 md:mx-12 lg:mx-32 my-28 md:my-0 '>
              <img className='self-center icon' src={mailboxIcon} alt=''></img>
              <h2 className='apply-page-h2 my-8 text-center'>
                通过google form给我们留言
              </h2>
              <p className='apply-page-p text-center' text-center>
                在 <a href='#'>这里</a>{" "}
                告诉我们我们可以改进的地方，未来希望看到的活动
              </p>
            </div>

            <div className='flex flex-col md:w-72'>
              <img
                className='self-center icon'
                src={conversationIcon}
                alt=''
              ></img>
              <h2 className='apply-page-h2 my-8 text-center'>
                加入我们的新生群
              </h2>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ApplyPage;
