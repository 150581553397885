import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className='container mx-auto mb-40'>
      <h1 className='front-page-h1 pt-40 pb-20 leading-loose text-center'>
        很抱歉，该页面正在升级中 <br />
        Please check back later!
      </h1>
      <div className='flex justify-center'>
        <Link className='btn-primary text-xl px-5 py-3' to='/'>
          返回主页
        </Link>
      </div>
    </div>
  );
}
