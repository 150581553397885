import React, { Component } from "react";
import "./AboutPage.css";
import aboutfig from "../images/aboutpagefig.png";
import Departments from "./Departments";

class AboutPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='main-section-about relative w-full bg-cover vertical justify-center'>
          <h1 className='w-full text-center text-red-50'>关于我们</h1>
        </div>
        <div className='horizontal container mx-auto pt-10'>
          <div className='vertical justify-center'>
            <p>
              CPU（Chinese People
              Union）成立于2002年，是美国加州大学伯克利分校的中国学生组织，我们的建立目的是为了服务广大伯克利中国留学生，让大家身在异国他乡也能拥有归属感与温暖。
            </p>
          </div>
          <img src={aboutfig} alt='' className='p-14 self-center md:ml-40' />
        </div>
        <hr className='topline my-10'></hr>
        <h1 className='department'>我们的部门</h1>
        <hr className='underline my-10' />
        <Departments />
      </React.Fragment>
    );
  }
}

export default AboutPage;
