import React from "react";
import departments from "../data/departments.json";

export default function Departments() {
  const list = departments.map((dept) => (
    <div className='py-5'>
      <h4>
        <span>{dept.name}</span>
      </h4>
      <p className='text-justify'>{dept.desc}</p>
    </div>
  ));
  return <div className='container mx-auto px-20 pb-20'>{list}</div>;
}
