import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWeixin,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className='bg-red-900 shadow-2xl absolute bottom-0 w-full'>
      <div className='header-container pb-5 pt-5'>
        <div className='flex items-left justify-between flex-col space-y-3'>
          <div className='flex justify-center'>
            <img src={logo} alt='CPU LOGO' width='60' />
          </div>
          <div>
            <FontAwesomeIcon icon={faWeixin} size='lg' inverse fixedWidth />
            <a href='#' className='media-item'>
              Wechat
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faFacebook} size='lg' inverse fixedWidth />
            <a
              href='https://www.facebook.com/berkeleyCPU'
              className='media-item'
            >
              Facebook
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faInstagram} size='lg' inverse fixedWidth />
            <a
              href='https://www.instagram.com/chinesepeopleunion'
              className='media-item'
            >
              Instagram
            </a>
          </div>
        </div>
        <div className='flex items-center justify-between flex-col space-y-3'>
          <div className='text-red-100 text-xl'>联系我们？</div>
          <Link to='/contact' className='footer-item'>
            学生
          </Link>
          <Link to='/contact' className='footer-item'>
            客户
          </Link>
          <Link to='/contact' className='footer-item'>
            赞助
          </Link>
        </div>
      </div>
      <hr className='bg-white' />
      <div className='pt-1 flex justify-center items-center'>
        <h3 className='flex items-center text-center text-red-100 text-xl p-2'>
          Copyright &copy; 2021 Chinese People Union
        </h3>
        <a href='https://www.ocf.berkeley.edu' className='flex items-center'>
          <img
            src='https://www.ocf.berkeley.edu/hosting-logos/ocf-hosted-penguin-dark.svg'
            alt='Hosted by the OCF'
            width='90'
          />
        </a>
      </div>
      <p className='pb-3 text-center text-red-300 text-sm'>
        We are a student group acting independent of the University of
        California. We take full responsibility for our organization and this
        website.
      </p>
    </footer>
  );
}

export default Footer;
