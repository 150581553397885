import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import FrontPage from "./components/FrontPage";
import ApplyPage from "./components/ApplyPage";
import NotFoundPage from "./components/NotFoundPage";
import UpdatingPage from "./components/UpdatingPage";
import AboutPage from "./components/AboutPage";

import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className='pb-72'>
        <Switch>
          <Route exact path='/' component={FrontPage} />
          <Route exact path='/about' component={AboutPage} />
          <Route exact path='/posts' component={UpdatingPage} />
          <Route exact path='/activity' component={UpdatingPage} />
          <Route exact path='/apply' component={ApplyPage} />
          <Route exact path='/contact' component={UpdatingPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
