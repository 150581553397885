import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className='container mx-auto mb-40'>
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <h1 className='front-page-h1 pt-40 pb-20 text-center'>
        很抱歉，您访问的页面不存在
      </h1>
      <div className='flex justify-center'>
        <Link className='btn-primary text-xl px-5 py-3' to='/'>
          返回主页
        </Link>
      </div>
    </div>
  );
}
