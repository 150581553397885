import React, { Component } from "react";
import "./FrontPage.css";
import chineseFinanceClub from "../images/ChineseFinanceClub.png";
import EtaOmegaChi from "../images/EtaOmegaChi.png";
import subscribePic from "../images/subscribe_pic.png";

class FrontPage extends Component {
  redirectTo = (target) => {
    this.props.history.push(target);
  };

  render() {
    return (
      <React.Fragment>
        {/* Main Section */}
        <div className='front-page-main-section relative w-full bg-cover'>
          <h1 className='absolute w-full text-center text-white'>
            CHINESE PEOPLE UNION
          </h1>
          <div className='flex w-full items-center justify-center'>
            <span className='absolute'></span>
          </div>
          <p className='absolute w-full text-center font-bold'>招新季来啦!</p>
          <div className='flex w-full items-center justify-center text-center'>
            <button
              className='absolute font-bold'
              onClick={() => this.redirectTo(`/apply`)}
            >
              Apply Now!
            </button>
          </div>
        </div>
        {/* About Page Section */}
        <div className='front-page-about-section mt-12 mb-20'>
          <div className='description leading-loose'>
            <p>
              伯克利最大的华人综合性社团之一，
              <br />
              立志于传播中华文化
              <br />
              为伯克利华人学生构建一个更好的留学生态。
            </p>
          </div>
          <div className='flex flex-row flex-wrap justify-center my-8'>
            <div className='container2 flex flex-col justify-between'>
              <h1 className='font-bold text-center'>8个</h1>
              <h2 className='text-center'>部门</h2>
            </div>
            <div className='container2 flex flex-col justify-center'>
              <h1 className='font-bold text-center'>10+</h1>
              <h2 className='text-center'>活动</h2>
            </div>
            <div className='container2 flex flex-col justify-center'>
              <h1 className='font-bold text-center'>20+</h1>
              <h2 className='text-center'>Workshops</h2>
            </div>
          </div>
          <div className='flex w-full justify-center'>
            <button
              className='p-4 front-page-button'
              onClick={() => this.redirectTo(`/about`)}
            >
              了解更多
            </button>
          </div>
        </div>
        {/* Mailing Page Section */}
        <div className='front-page-mailing-section py-20'>
          <h1 className='front-page-h1 font-bold mx-8 text-center'>
            想听到更多来自我们的信息？
          </h1>
          <p className='front-page-p my-8 mx-4 text-center'>
            收取到我们的最新活动, 招新截止日期, workshops 和 professional talks
          </p>
          <div className='flex flex-col md:flex-row justify-center'>
            <img src={subscribePic} alt='' className='self-center'></img>
            <button
              className='front-page-button self-center -ml-0 md:ml-20 my-8 p-4'
              onClick={() => this.redirectTo(`/#`)}
            >
              订阅我们的邮件
            </button>
          </div>
          <h1 className='front-page-h1 font-bold mx-8 mt-12 text-center'>
            或者关注微信公众号CPU_Berkeley
          </h1>
        </div>
        {/* Partnership Section */}
        <div className='partnership-section my-20'>
          <div className='mb-20'>
            <h1 className='front-page-h1 font-bold mx-8 md:mx-32 my-8'>
              我们的合作伙伴
            </h1>
            <div className='container flex flex-row flex-wrap justify-center md:justify-start'>
              <img
                src={chineseFinanceClub}
                alt=''
                className='self-center md:ml-40'
              ></img>
              <img
                src={EtaOmegaChi}
                alt=''
                className='self-center md:ml-40'
              ></img>
            </div>
          </div>
          <div className='mt-20'>
            <h1 className='front-page-h1 font-bold mx-8 md:mx-32 my-8'>
              想和我们合作？
            </h1>
            <p className='front-page-p mx-8 md:mx-32'>
              在business@cpunion.berkeley.edu给我们留言
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FrontPage;
