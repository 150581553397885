import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

function Header() {
  return (
    <div className='header'>
      <div className='header-container'>
        <div className='flex items-center'>
          <img src={logo} alt='CPU LOGO' width='60' />
        </div>
        <div className='flex items-center'>
          <Link to='/' className='nav-item'>
            主页
          </Link>
          <Link to='/about' className='nav-item'>
            关于我们
          </Link>
          <Link to='/posts' className='nav-item'>
            文章
          </Link>
          <Link to='/activity' className='nav-item'>
            活动
          </Link>
          <Link to='/apply' className='nav-item btn-primary'>
            加入我们
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
